import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import products from "../../assets/products.json";

const MainFooter = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleGo = (e) => {
    e.preventDefault();
    navigate("/contact#contact-form-section", { state: { email } });
  };

  return (
    <footer className="main__footer">
      <div className="footer-top">
        <div className="auto-container">
          <div className="top-inner">
            <div className="left__top">
              <h3>Looking For best It business solution</h3>
            </div>
            <div className="right__top">
              <div className="btn-box">
                <Link
                  to="/contact#contact-form-section"
                  className="theme-btn theme-btn-one"
                >
                  <i className="icon-02"></i> Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__one">
        <div className="footer-widget-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div
                  className="footer-widget footer-logo-widget wow fadeInUp animated animated"
                  data-wow-delay="00ms"
                  data-wow-duration="1500ms"
                  style={{
                    visibility: "visible",
                    animationDuration: "1500ms",
                    animationDelay: "0ms",
                    animationName: "fadeInUp",
                  }}
                >
                  <figure className="footer-logo">
                    <a href="/">
                      <img src="assets/logo.png" alt="" />
                    </a>
                  </figure>
                  <div className="text">
                    <p>
                      Our client-centric approach focuses on understanding your
                      unique needs, delivering bespoke solutions, and forming
                      strategic partnerships that drive sustainable business
                      growth and innovation.
                    </p>
                  </div>
                  <div className="btn-box">
                    <Link to="/about" className="theme-btn theme-btn-one">
                      <i className="icon-02"></i> About Us
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div
                  className="footer-widget news-letter-widget ml_80 wow fadeInUp animated animated"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                  style={{
                    visibility: "visible",
                    animationDuration: "1500ms",
                    animationDelay: "200ms",
                    animationName: "fadeInUp",
                  }}
                >
                  <div className="widget-title">
                    <h4>Newletter</h4>
                  </div>
                  <div className="text">
                    <p>
                      Embark on a seamless journey where technology and talent
                      converge to create unparalleled digital solutions.
                    </p>
                  </div>
                  <div className="subscribe-inner">
                    <form className="subscribe-form">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Your mail address *"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          required
                        />
                        <div className="btn-box">
                          <button
                            className="theme-btn theme-btn-one"
                            type="submit"
                            onClick={handleGo}
                          >
                            <i className="icon-02"></i> GO
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div
                  className="footer-widget office-widget wow fadeInUp animated animated"
                  data-wow-delay="400ms"
                  data-wow-duration="1500ms"
                  style={{
                    visibility: "visible",
                    animationDuration: "1500ms",
                    animationDelay: "400ms",
                    animationName: "fadeInUp",
                  }}
                >
                  <div className="widget-title">
                    <h4>Official info:</h4>
                  </div>
                  <div className="widget-content">
                    <div className="text">
                      <p>111 NE 1st, Miami, Florida</p>
                    </div>
                    <a href="tel:+17185099729">+1 (718) 509 9729</a>
                    <a href="mailto:shane@apexic.co">shane@apexic.co</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column pr-0">
                <div
                  className="footer-widget gallery-widget wow fadeInUp animated animated"
                  data-wow-delay="600ms"
                  data-wow-duration="1500ms"
                  style={{
                    visibility: "visible",
                    animationDuration: "1500ms",
                    animationDelay: "600ms",
                    animationName: "fadeInUp",
                  }}
                >
                  <div className="widget-title">
                    <h4>Products</h4>
                  </div>
                  <div className="widget_content">
                    <ul className="instagram_list clearfix">
                      {products.map((product) => (
                        <li key={product.id}>
                          <div className="inner__box">
                            <div className="image__box">
                              <Link
                                className="lightbox-image"
                                to={`/product/${product.id}`}
                              >
                                <img src={product.imageForHome} alt="" />
                              </Link>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="auto-container">
            <div className="bottom-inner">
              <div className="copyright">
                <p>
                  © 2023 <Link to="/">Apexic Solutions.</Link> All rights
                  reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
