import { Link } from "react-router-dom";
import { Slide, Rotate } from "react-awesome-reveal";

const AboutSection = () => {
  return (
    <section className="about__section p_relative see__pad">
      <div className="pattern-layer">
        <Slide direction="left" duration={2000}>
          <div
            className="pattern-2 p_absolute"
            data-parallax='{"x": -50}'
            style={{
              backgroundImage: "url(assets/shape-02.png)",
            }}
          ></div>
        </Slide>
      </div>
      <div className="boild__text">
        <h1>Apexic Solutions</h1>
      </div>
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="about__block p_relative">
              <Rotate triggerOnce>
                <figure className="wow zoomIn animated animated">
                  <img src="assets/about-us.png" alt="" />
                </figure>
              </Rotate>
              <div
                className="funfact__content about"
                style={{
                  backgroundImage: "url(assets/shape-01.png)",
                }}
              >
                <div className="count-outer count-box counted">
                  <h1 className="count-text" data-speed="1500" data-stop="2">
                    10
                  </h1>
                  <span>+</span>
                </div>
                <p>Clients Worldwide</p>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-5 col-md-12">
            <div className="about__text__block">
              <Slide direction="up" triggerOnce>
                <div className="sub__title">
                  <h4>About Company</h4>
                </div>
              </Slide>
              <Slide direction="up" triggerOnce>
                <div className="title two">
                  <h2>
                    Bridging Technology and Talent to Power Your Digital
                    Transformation with
                    <span> Technology</span>.
                  </h2>
                </div>
              </Slide>
              <Slide direction="up" triggerOnce>
                <div className="texts">
                  <p>
                    We offer a holistic and integrated approach to software
                    consultancy, ensuring your business navigates the digital
                    landscape with expertise and precision. We meld a myriad of
                    crucial services into a unified solution
                  </p>
                </div>
              </Slide>
              <Slide direction="up" triggerOnce>
                <div className="btn-box">
                  <Link to="/about" className="theme-btn theme-btn-one">
                    <i className="icon-02"></i> About more
                  </Link>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
