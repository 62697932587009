import positions from "../../assets/positions.json";

const PositionList = () => {
  if (!positions.length) {
    return (
      <div className="position-list d-flex flex-column align-items-center">
        <div className="position__details container my-5">
          <div className="data pb-3 text-center">
            <h2>Senior Full Stack Developer (React, Node.js, TypeScript)</h2>
          </div>
          <p className="pb-3">
            Company: Apexic Solutions
            <br /> Location: US Remote
            <br /> Job Type: Full-Time
          </p>

          <div className="data">
            <h5>Description</h5>
          </div>
          <p className="pb-3">
            Apexic Solutions, a leading software consultancy known for our
            commitment to security and transparency, is seeking an experienced
            Senior Full Stack Developer to join our dynamic team. We work
            directly with a diverse range of clients, from innovative startups
            to large enterprises, delivering cutting-edge software solutions
            that drive success. If you are passionate about developing
            high-quality software and thrive in a fast-paced environment, we
            would love to hear from you.
          </p>

          <div className="data">
            <h5>Responsibilities</h5>
          </div>
          <div className="data__list">
            <ul>
              <li>
                <i className="icon-05"></i>Develop and maintain web applications
                using React, Node.js, and TypeScript.
              </li>
              <li>
                <i className="icon-05"></i>Collaborate with cross-functional
                teams to define, design, and ship new features.
              </li>
              <li>
                <i className="icon-05"></i>Ensure the performance, quality, and
                responsiveness of applications.
              </li>
              <li>
                <i className="icon-05"></i>Identify and correct bottlenecks and
                fix bugs.
              </li>
              <li>
                <i className="icon-05"></i>Help maintain code quality,
                organization, and automatization.
              </li>
              <li>
                <i className="icon-05"></i>Stay up-to-date with new trends and
                technologies in the web development field.
              </li>
              <li>
                <i className="icon-05"></i>Lead and mentor junior developers,
                and contribute to team discussions and decision-making
                processes.
              </li>
            </ul>
          </div>
          <div className="data">
            <h5>Qualifications</h5>
          </div>
          <div className="data__list">
            <ul>
              <li>
                <i className="icon-05"></i>Minimum 6 years of professional
                experience in full-stack development, specifically with React,
                Node.js, and TypeScript.
              </li>
              <li>
                <i className="icon-05"></i>Strong proficiency in front-end
                technologies, including HTML5, CSS3, and JavaScript.
              </li>
              <li>
                <i className="icon-05"></i>Expertise in back-end development,
                with a deep understanding of Node.js.
              </li>
              <li>
                <i className="icon-05"></i>Experience with RESTful APIs, GraphQL
                is a plus.
              </li>
              <li>
                <i className="icon-05"></i>Familiarity with modern front-end
                build pipelines and tools.
              </li>
              <li>
                <i className="icon-05"></i>Experience with common front-end
                development tools such as Babel, Webpack, NPM, etc.
              </li>
              <li>
                <i className="icon-05"></i>A knack for benchmarking and
                optimization.
              </li>
              <li>
                <i className="icon-05"></i>Familiarity with code versioning
                tools, such as Git.
              </li>
              <li>
                <i className="icon-05"></i>Bachelor’s or Master’s degree in
                Computer Science, Engineering, or a related field.
              </li>
            </ul>
          </div>

          <div className="data">
            <h5>Additional Requirements</h5>
          </div>
          <div className="data__list">
            <ul>
              <li>
                <i className="icon-05"></i>Must pass a comprehensive background
                check.
              </li>
              <li>
                <i className="icon-05"></i>Security clearance is not mandatory
                but is a plus.
              </li>
              <li>
                <i className="icon-05"></i>Authorized to work in the United
                States.
              </li>
            </ul>
          </div>

          <div className="data">
            <h5>We Offer</h5>
          </div>
          <div className="data__list">
            <ul>
              <li>
                <i className="icon-05"></i>A collaborative and supportive work
                environment that values innovation and creativity.
              </li>
              <li>
                <i className="icon-05"></i>Opportunities to work on exciting
                projects with a diverse client base.
              </li>
              <li>
                <i className="icon-05"></i>Competitive salary and benefits
                package.
              </li>
              <li>
                <i className="icon-05"></i>Professional development and career
                growth opportunities.
              </li>
            </ul>
          </div>

          <div className="data">
            <h5>How to Apply</h5>
          </div>
          <p>
            Interested candidates should submit their resume to{" "}
            <a href="mailto:hr@apexic.co">hr@apexic.co</a>, a cover letter
            highlighting relevant experience, and any applicable portfolio links
            or GitHub profiles. We look forward to learning about how your
            experience, skills, and passions make you the perfect fit for this
            role at Apexic Solutions.
          </p>
          <hr />
          <p>
            Apexic Solutions is an Equal Opportunity Employer. We celebrate
            diversity and are committed to creating an inclusive environment for
            all employees.
          </p>
        </div>
        {/* <img src="/assets/no-item.png" alt="no-items" width={90} />
        <div>There are no jobs right now.</div> */}
      </div>
    );
  }
  return positions.map(() => <div></div>);
};

export default PositionList;
