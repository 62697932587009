import BannerSection from "./BannerSection";
import AboutSection from "../Home/AboutSection";
import WhyChooseSection from "../Home/WhyChooseSection";
// import TeamSection from "../Home/TeamSection";
import PrideSection from "./PrideSection";
// import PriceSection from "./PriceSection";

const AboutPage = () => {
  return (
    <>
      <BannerSection />
      <AboutSection />
      <WhyChooseSection />
      {/* <TeamSection /> */}
      <PrideSection />
      {/* <PriceSection /> */}
    </>
  );
};

export default AboutPage;
