const GoogleMapSection = () => {
  return (
    <div className="google__map">
      <figure>
        <img
          src="assets/map.jpg"
          alt=""
          style={{ filter: "brightness(60%)" }}
        />
      </figure>
    </div>
  );
};

export default GoogleMapSection;
