import React, { useState } from "react";

import TopHeader from "./TopHeader";
import LowerHeader from "./LowerHeader";
import StickyHeader from "./StickyHeader";
import MobileMenu from "./MobileMenu";

import useMouseScroll from "../../hooks/useMouseScroll";
import MainFooter from "./MainFooter";

const Layout = ({ children }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const scrollPosition = useMouseScroll();

  const cls = scrollPosition > 100 ? "fixed-header" : "";

  return (
    <div
      className={
        showMobileMenu ? "mobile-menu-visible boxed_wrapper" : "boxed_wrapper"
      }
    >
      <header className={"main-header header__style__one " + cls}>
        <TopHeader />
        <LowerHeader setShowMobileMenu={setShowMobileMenu} />
        <StickyHeader />
      </header>
      <MobileMenu setShowMobileMenu={setShowMobileMenu} />
      {children}
      <MainFooter />
    </div>
  );
};

export default Layout;
