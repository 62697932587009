import { Slide } from "react-awesome-reveal";

const ServiceSection = () => {
  return (
    <section className="service__section p_relative">
      <div className="auto-container">
        <div className="service__data">
          <div className="title__data">
            <div className="sub__title">
              <h4>Better Future</h4>
            </div>
            <div className="title">
              <h2>Explore Our Services</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated animated">
              <Slide direction="up" duration={500} triggerOnce>
                <div className="service__block">
                  <div className="service__icon">
                    <i className="icon-04"></i>
                    <div className="service__icon__two"></div>
                  </div>
                  <div className="service__text">
                    <h4>
                      {/* <a href="service-details.html"> */}
                      Software Development
                      {/* </a> */}
                    </h4>
                    <p>
                      Providing professional services to startups, small
                      businesses to build their products with cheap price, and
                      high quality
                    </p>
                  </div>
                  <div className="service__button">
                    {/* <a href="service-details.html">
                  <i className="icon-05"></i>
                  </a> */}
                  </div>
                </div>
              </Slide>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated animated">
              <Slide direction="up" duration={800} triggerOnce>
                <div className="service__block">
                  <div className="service__icon">
                    <i className="icon-08"></i>
                    <div className="service__icon__two"></div>
                  </div>
                  <div className="service__text">
                    <h4>Talent Sourcing</h4>
                    <p>
                      Providing high talented, vetted professionals across the
                      world for your existing team, or building new teams
                    </p>
                  </div>
                  <div className="service__button"></div>
                </div>
              </Slide>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated animated">
              <Slide direction="up" duration={1100} triggerOnce>
                <div className="service__block">
                  <div className="service__icon">
                    <i className="icon-12"></i>
                    <div className="service__icon__two"></div>
                  </div>
                  <div className="service__text">
                    <h4>Supporting Entrepreneurs</h4>
                    <p>
                      Providing full services to entrepreneurs who have
                      innovative ideas to start the business, and start the
                      product
                    </p>
                  </div>
                  <div className="service__button"></div>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
