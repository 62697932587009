import { Link } from "react-router-dom";

import SpanArray from "../../components/SpanArray";
import LogoIcon from "../../components/Icon/logo";
import useMouseScroll from "../../hooks/useMouseScroll";

const BannerOne = () => {
  const scrollPosition = useMouseScroll();

  return (
    <section className="banner__one">
      <div className="pattern-layer">
        <div
          className="banner__icon pattern-1 p_absolute"
          dataparallax='{"x": 100}'
          style={{
            transform: `translate3d(${scrollPosition / 10.0
              }px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)`,
            transition: "transform 600ms ease-in-out",
            backgroundImage: "url(assets/banner-shap-01.png)",
          }}
        ></div>
      </div>
      <div className="banner__data p_relative">
        <div
          className="banner__bg"
          style={{ backgroundImage: "url(assets/banner-bg.png)" }}
        ></div>
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="banner__left">
                <div className="sub__title">
                  <h4>INNOVATIVE APPROACH</h4>
                </div>
                <div className="banner__title">
                  <h1>
                    Advancing with <span>Technology</span>
                  </h1>
                </div>
                <div className="text">
                  <p>
                    Empowering Your Tech Journey, Unifying Software Solutions, Talent Sourcing, and Strategic Consultancy Under One Roof For Businesses, and Entrepreneurs
                  </p>
                </div>
                <div className="btn-box">
                  <Link to='/contact#contact-form-section' className="theme-btn theme-btn-one">
                    <i className="icon-02"></i> Contact Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-4 col-md-12 ">
              <div className="banner__right p_relative">
                <div className="image__one">
                  <figure
                    className="image-box wow slideInUp animated animated animated animated animated animated"
                    data-wow-delay="00ms"
                    data-wow-duration="1500ms"
                  >
                    <img src="/assets/banner-01.png" alt="banner-01" />
                  </figure>
                </div>
                <div className="icon__image__banner">
                  <div className="circle-main">
                    <div className="circle">
                      <LogoIcon color="#f20d79" width={60} height={60} />
                      <div className="round-text">
                        <div className="text_1">
                          <p className="text_2">
                            <SpanArray letters="Fast Best Crest   Fast Best Crest" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerOne;
