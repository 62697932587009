import MediumIcon from "../Icon/medium";

const TopHeader = () => {
  return (
    <div className="header-top-one">
      <div className="auto-container">
        <div className="top__inner">
          <div className="top__hrader__left">
            <ul>
              <li>
                <span>Address :</span> 111 NE 1st, Miami, Florida
              </li>
              <li>/</li>
              <li>
                <span>Email :</span>{" "}
                <a href="mailto:shane@apexic.co">shane@apexic.co</a>
              </li>
            </ul>
          </div>
          <div className="top__hrader__right">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/people/Apexic-Solutions/61552232943502/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="icon-10"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/ApexicSolutions/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="icon-14"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/apexic-solutions/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="icon-01"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://medium.com/apexic-solutions/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i>
                    <MediumIcon color="white" width={30} height={30} />
                  </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
