import ProductItem from "./ProductItem";
import products from "../../assets/products.json";

const ProductSection = () => {
  return (
    <div className="project__page p_relative see__pad">
      <div className="row">
        {products.map((product) => (
          <ProductItem
            product={product}
            key={product.id}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductSection;
