import { Link, useLocation } from "react-router-dom";

const RouteLinks = [
  { href: "/", name: "Home" },
  { href: "/about", name: "About" },
  { href: "/products", name: "Products" },
  { href: "/careers", name: "Career" },
  { href: "/contact", name: "Contact" },
];

const LowerHeader = ({ setShowMobileMenu }) => {
  const location = useLocation();

  return (
    <div className="header-lower">
      <div className="auto-container">
        <div className="outer-box">
          <div className="logo-box">
            <figure className="logo">
              <a href="/">
                <img className="logo-image" src="/assets/logo.png" alt="" />
              </a>
            </figure>
          </div>
          <div className="menu-area clearfix">
            <div
              className="mobile-nav-toggler"
              onClick={() => {
                setShowMobileMenu(true);
              }}
            >
              <i className="icon-bar"></i>
              <i className="icon-bar"></i>
              <i className="icon-bar"></i>
            </div>
            <nav className="main-menu navbar-expand-md navbar-light">
              <div
                className="collapse navbar-collapse show clearfix"
                id="navbarSupportedContent"
              >
                <ul className="navigation clearfix home-menu">
                  {RouteLinks.map((routeLink) => (
                    <li
                      key={routeLink.href}
                      className={
                        routeLink.href === location.pathname ? "current" : ""
                      }
                    >
                      <Link to={routeLink.href}>{routeLink.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </div>
          <div className="btn-box">
            <Link to='/contact#contact-form-section' className="theme-btn theme-btn-one">
              <i className="icon-02"></i> Discuss
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LowerHeader;
