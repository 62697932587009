import { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Slide } from "react-awesome-reveal";
import emailjs from "@emailjs/browser";

const ContactFormSection = () => {
  const location = useLocation();
  const [email, setEmail] = useState(location.state?.email || "");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_3gofvkl",
        "template_6f14rm9",
        form.current,
        "Z5EkctQIzzxHVeVoa"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section
      className="contact__page p_relative see__pad"
      id="contact-form-section"
    >
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-ms-12">
            <div className="contact__left">
              <figure>
                <Slide direction="up" triggerOnce>
                  <img src="assets/contact.jpg" alt="contact" />
                </Slide>
              </figure>
              <Slide direction="up" triggerOnce>
                <h4>Contact Information</h4>
              </Slide>
              <div className="contact__info">
                <Slide direction="up" triggerOnce>
                  <div className="contact__block">
                    <div className="icon">
                      <i className="icon-19"></i>
                    </div>

                    <div className="contact__text">
                      <a href="tel:+17185099729">+1 (718) 509 9729</a>
                    </div>
                  </div>
                </Slide>
                <Slide direction="up" triggerOnce>
                  <div className="contact__block">
                    <div className="icon two">
                      <i className="icon-20"></i>
                    </div>
                    <div className="contact__text">
                      <a href="mailto:shane@apexic.co">shane@apexic.co</a>
                    </div>
                  </div>
                </Slide>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-12">
            <div className="contact__right">
              <div className="form__title">
                <div className="title__data">
                  <Slide direction="up" triggerOnce>
                    <div className="sub__title">
                      <h4>Contact Us</h4>
                    </div>
                  </Slide>
                  <Slide direction="up" triggerOnce>
                    <div className="title">
                      <h2>Get a Free Quote</h2>
                    </div>
                  </Slide>
                </div>
              </div>
              <div className="form-inner">
                <Slide direction="up" triggerOnce>
                  <form
                    className="default-form"
                    ref={form}
                    onSubmit={sendEmail}
                  >
                    <div className="row clearfix">
                      <div className="col-xl-6 form-group">
                        <input
                          name="from_name"
                          placeholder="Your Name *"
                          required
                        />
                      </div>
                      <div className="col-xl-6 col-lg-12 form-group">
                        <input
                          type="email"
                          name="from_email"
                          placeholder="Your Email *"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          required
                        />
                      </div>
                      <div className="col-xl-6 col-lg-12 form-group">
                        <input
                          type="text"
                          name="email_subject"
                          placeholder="Your Subject "
                          required
                        />
                      </div>
                      <div className="col-xl-6 col-lg-12 form-group">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Your Phone "
                          required
                        />
                      </div>
                      <div className="ccol-xl-6 col-lg-12 form-group">
                        <textarea
                          name="html_message"
                          placeholder="Message"
                        ></textarea>
                      </div>
                      <div className="col-xl-12 form-group message-btn btn-box">
                        <button
                          className="theme-btn theme-btn-one"
                          type="submit"
                          name="submit-form"
                        >
                          Send your Message
                        </button>
                      </div>
                    </div>
                  </form>
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactFormSection;
