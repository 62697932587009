import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import BrandSectionDetails from "./BrandSectionDetails";
import useMouseScroll from "../../hooks/useMouseScroll";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 7,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 7,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 7,
    partialVisibilityGutter: 40,
  },
};

const BrandSection = () => {
  const scrollPosition = useMouseScroll();

  const brandSections = [
    { id: 0, content: "Technology *" },
    { id: 1, content: "IT Services *" },
    { id: 2, content: "IT Services *" },
    { id: 3, content: "Development *" },
    { id: 4, content: "Technology *" },
    { id: 5, content: "IT Services *" },
    { id: 6, content: "IT Services *" },
    { id: 7, content: "Development *" },
  ];

  return (
    <div className="brand__section p_relative see__pad">
      <div className="pattern-layer">
        <div
          className="pattern-3 p_absolute"
          style={{
            transform: `translate3d(0px, ${
              -40.29 - (scrollPosition - 5500) / 15.0
            }px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)`,
            transition: "transform 600ms ease-in-out",
            backgroundImage: "url(/assets/shape-05.png)",
          }}
        ></div>
      </div>
      <div className="brand-carousel owl-carousel owl-theme  nav-style-one owl-loaded owl-drag">
        <div className="owl-stage-outer">
          <div
            className="owl-stage"
            style={{
              transform:
                "translate3d(-1915px, 0px, 0px); transition: all 1s ease 0s",
              width: "5108px",
            }}
          >
            <Carousel
              responsive={responsive}
              showDots={false}
              infinite
              autoPlay
              autoPlaySpeed={2000}
              removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
            >
              {brandSections.map((brandSectionDetail) => (
                <BrandSectionDetails
                  brandSectionDetail={brandSectionDetail.content}
                  key={brandSectionDetail.id}
                />
              ))}
            </Carousel>
          </div>
        </div>
        {/* <div className="owl-nav">
          <button type="button" role="presentation" className="owl-prev">
            <span className="icon-05"></span>
          </button>
          <button type="button" role="presentation" className="owl-next">
            <span className="icon-06"></span>
          </button>
        </div> */}
        {/* <div className="owl-dots">
          <button role="button" className="owl-dot active">
            <span></span>
          </button>
          <button role="button" className="owl-dot">
            <span></span>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default BrandSection;
