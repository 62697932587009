import { Slide } from "react-awesome-reveal";

const TechnologySection = () => (
  <section className="choose__us three p_relative ">
    <div className="pattern-layer">
      <div
        className="pattern-2 p_absolute"
        data-parallax='{"x": -50}'
        style={{
          transform:
            "translate3d(-100px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)",
          backgroundImage: "url(assets/shape-02.png)",
        }}
      ></div>
    </div>
    <div className="auto-container">
      <div className="title__data">
        <Slide direction="up" triggerOnce>
          <div className="sub__title">
            <h4>What we can provide</h4>
          </div>
        </Slide>
        <Slide direction="up" triggerOnce>
          <div className="title">
            <h2>High Tech Support</h2>
          </div>
        </Slide>
      </div>
      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-12">
          <Slide direction="up" triggerOnce>
            <div className="choose_us_block">
              <h2>Web Development</h2>
              <ul>
                <li>Frontend</li>
                <li>Backend</li>
                <li>Database</li>
                <li>DevOps</li>
              </ul>
            </div>
          </Slide>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-12">
          <Slide direction="up" triggerOnce>
            <div className="choose_us_block">
              <h2>Blockchain</h2>
              <ul>
                <li>Ethereum</li>
                <li>Solana</li>
                <li>Polygon</li>
              </ul>
            </div>
          </Slide>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-12">
          <Slide direction="up" triggerOnce>
            <div className="choose_us_block">
              <h2>Mobile & Desktop</h2>
              <ul>
                <li>React Native</li>
                <li>Ionic</li>
                <li>Electron</li>
              </ul>
            </div>
          </Slide>
        </div>
      </div>
    </div>
  </section>
);

export default TechnologySection;
