import BannerSection from "./BannerSection";
import PositionList from "./PositionList";

const CareerPage = () => {
  return (
    <>
      <BannerSection />
      <PositionList />
    </>
  );
};

export default CareerPage;
