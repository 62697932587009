import { createPortal } from "react-dom";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

const Header = () => {
  const swiper = useSwiper();

  const parentSection = document.getElementById("testimonial-section");

  if (!parentSection) return <></>;

  return createPortal(
    <div>
      <div className="auto-container">
        <div className="testimonial__top">
          <div className="title__data">
            <div className="sub__title">
              <h4>Testimonial </h4>
            </div>
            <div className="title">
              <h2>What Our Client Says</h2>
            </div>
          </div>
          <div className="swiper__button">
            <div
              className="button_next"
              role="button"
              aria-label="Next slide"
              onClick={() => swiper.slidePrev()}
            >
              <i className="icon-06"></i>
            </div>
            <div
              className="button_prev"
              role="button"
              aria-label="Previous slide"
              onClick={() => swiper.slideNext()}
            >
              <i className="icon-05"></i>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("testimonial-section")
  );
};

const testimonials = [
  {
    name: "Json Wardrop",
    position: "Entrepreneur",
    comment:
      "Starting our business with them meant strategic, tech-savvy guidance at every step. Their unwavering support has been key to navigating our startup challenges!",
  },
  {
    name: "Roger Marvinney",
    position: "Owner",
    comment:
      "Apexic turned our startup idea into a successful reality with their strategic insights and top-tier development skills. A true ally in our digital journey!",
  },
  {
    name: "Glenn Hansen",
    position: "CTO",
    comment:
      "Apexic Solution's deep expertise and innovative developers have been pivotal, keeping our projects both current and future-ready. A truly unparalleled partnership!",
  },
  {
    name: "Kamil Asfour",
    position: "CTO",
    comment:
      "From robust software solutions to efficient IT outstaffing, they have been a one-stop-shop for all our tech needs, driving our digital triumph!",
  },
];

const TestimonialSection = () => {
  return (
    <section
      className="testimonial__section p_relative"
      id="testimonial-section"
    >
      <div>
        <Swiper
          spaceBetween={50}
          breakpoints={{
            1600: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 3,
            },
            992: {
              slidesPerView: 2,
            },
            576: {
              slidesPerView: 1,
            },
          }}
          style={{
            cursor: "grab",
          }}
          loop
        >
          <Header />
          {[1, 2, 3, 4, 1, 2, 3, 4].map((item, index) => (
            <SwiperSlide
              key={item.toString() + index}
              className="testimonials__block__one"
            >
              <div className="test__bg"></div>
              <div className="testimonials__info">
                <div className="authore__img">
                  <figure className="image">
                    <img src={`assets/author-0${item}.png`} alt="" />
                  </figure>
                </div>
                <div className="authore__rating">
                  <ul>
                    <li>
                      <i className="icon-13"></i>
                    </li>
                    <li>
                      <i className="icon-13"></i>
                    </li>
                    <li>
                      <i className="icon-13"></i>
                    </li>
                    <li>
                      <i className="icon-13"></i>
                    </li>
                    <li>
                      <i className="icon-13"></i>
                    </li>
                  </ul>
                </div>
                <div className="authore__info">
                  <h5>{testimonials[index % 4].name}</h5>
                  <p>{testimonials[index % 4].position}</p>
                </div>
                <div className="testimonials__text">
                  <p>{testimonials[index % 4].comment}</p>
                </div>
              </div>
              <div className="quote">
                <span className="icon-03"></span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default TestimonialSection;
