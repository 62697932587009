import { Link } from "react-router-dom";

const BannerSection = () => {
  return (
    <section className="page__title p_relative">
      <div
        className="bg-layer"
        style={{
          backgroundImage: "url(assets/page-title-bg.jpg)",
        }}
      ></div>
      <div className="auto-container">
        <div className="content__box p_relative">
          <ul className="bread__crumb">
            <li>
              <Link to="/">Home </Link>
            </li>
            <li>
              {" "}
              <span> -</span>Contact{" "}
            </li>
          </ul>
          <h1 className="title">Contact</h1>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
