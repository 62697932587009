import BannerSection from "./BannerSection";
import ProductSeciton from "./ProductSection";

const ProductsPage = () => {
  return (
    <>
      <BannerSection />
      <ProductSeciton />
    </>
  );
};

export default ProductsPage;
