import BannerOne from "./BannerOne";
import ServiceSection from "./ServiceSection";
import AboutSection from "./AboutSection";
import WhyChooseSection from "./WhyChooseSection";
import TechnologySection from "./TechnologySection";
// import TeamSection from "./TeamSection";
import AccomplisedSection from "./AccomplisedSection";
import TestimonialSection from "./TestimonialSection";
import BrandSection from "./BrandSection";
import BlogSection from "./BlogSection";

const HomePage = () => {
  return (
    <>
      <BannerOne />
      <ServiceSection />
      <AboutSection />
      <WhyChooseSection />
      <TechnologySection />
      {/* <TeamSection /> */}
      <AccomplisedSection />
      <TestimonialSection />
      <BrandSection />
      <BlogSection />
    </>
  );
};

export default HomePage;
