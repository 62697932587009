import BannerSection from "./BannerSection";
import ContactFormSection from "./ContactFormSection";
import GoogleMapSection from "./GoogleMapSection";

const ContactPage = () => {
  return (
    <>
      <BannerSection />
      <ContactFormSection />
      <GoogleMapSection />
    </>
  );
};

export default ContactPage;
