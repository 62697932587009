import { Slide } from "react-awesome-reveal";

const WhyChooseSection = () => {
  return (
    <section className="choose__us p_relative">
      <div className="choose__us__data">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="choose__block">
                <figure>
                  <img src="assets/choose-01.png" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="choose__block__right">
                <div className="title__data">
                  <Slide direction="up" triggerOnce>
                    <div className="sub__title">
                      <h4>Why choose us</h4>
                    </div>
                  </Slide>
                  <Slide direction="up" triggerOnce>
                    <div className="title">
                      <h2>We Provide The Best Work</h2>
                    </div>
                  </Slide>
                </div>
                <Slide direction="right" triggerOnce>
                  <div className="team__data">
                    <div className="team__data__left">
                      <img src="assets/choose-02.svg" alt="" />
                    </div>
                    <div className="team__data__right">
                      <h3>Innovative Software Development :</h3>
                      <p>
                        Turning ideas into tangible, high-performance digital
                        products with our expert development team.
                      </p>
                    </div>
                  </div>
                </Slide>
                <Slide direction="right" triggerOnce>
                  <div className="team__data">
                    <div className="team__data__left">
                      <img src="assets/choose-02.svg" alt="" />
                    </div>
                    <div className="team__data__right">
                      <h3>Strategic IT Outstaffing :</h3>
                      <p>
                        Enabling you to scale effectively and meet project
                        demands with our specialist teams, who integrate
                        seamlessly into your operations.
                      </p>
                    </div>
                  </div>
                </Slide>
                <Slide direction="right" triggerOnce>
                  <div className="team__data three">
                    <div className="team__data__left">
                      <img src="assets/choose-02.svg" alt="" />
                    </div>
                    <div className="team__data__right">
                      <h3>Global Talent Sourcing and Vetting :</h3>
                      <p>
                        Delivering top-tier, thoroughly vetted talent from
                        across the globe directly to your projects, ensuring a
                        perfect fit every time.
                      </p>
                    </div>
                  </div>
                </Slide>
                <Slide direction="right" triggerOnce>
                  <div className="team__data three">
                    <div className="team__data__left">
                      <img src="assets/choose-02.svg" alt="" />
                    </div>
                    <div className="team__data__right">
                      <h3>Open Source Involvement :</h3>
                      <p>
                        Delivering top-tier, thoroughly vetted talent from
                        across the globe directly to your projects, ensuring a
                        perfect fit every time.
                      </p>
                    </div>
                  </div>
                </Slide>
                <Slide direction="right" triggerOnce>
                  <div className="team__data three">
                    <div className="team__data__left">
                      <img src="assets/choose-02.svg" alt="" />
                    </div>
                    <div className="team__data__right">
                      <h3>Entrepreneurial Assistance :</h3>
                      <p>
                        Guiding startups and entrepreneurs from ideation through
                        to implementation and growth with strategic consultation
                        and robust technological support.
                      </p>
                    </div>
                  </div>
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseSection;
