const BrandSectionDetails = ({ brandSectionDetail }) => (
  <div className="owl-item cloned">
    <div className="brand__content">
      <div className="brand__name">
        <h1>{brandSectionDetail}</h1>
      </div>
    </div>
  </div>
);

export default BrandSectionDetails;
