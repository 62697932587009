import { Link } from "react-router-dom";
import products from "../../assets/products.json";

const DetailSection = ({ id }) => {
  const product = products.filter((item) => item.id === Number(id))[0]

  return (
    <section className="project__details p_relative">
      <div className="auto-container">
        <div className="image">
          <figure>
            <img src={product.image} alt="" />
          </figure>
        </div>
        <div className="pro__info">
          <ul>
            <li>
              <div className="name">
                <p>Client :</p>
              </div>
              <div className="name__two">
                <h4>{product.client}</h4>
              </div>
            </li>
            <li>
              <div className="name">
                <p>Category :</p>
              </div>
              <div className="name__two">
                <h4>{product.category}</h4>
              </div>
            </li>
            <li>
              <div className="name">
                <p>Date :</p>
              </div>
              <div className="name__two">
                <h4>{product.date}</h4>
              </div>
            </li>
          </ul>
        </div>
        <div dangerouslySetInnerHTML={{ __html: product.content }} />
        <div className="project__button">
          <div className="button__one">
            <Link
              to={`/product/${product.id - 1}`}
              className={`${product.id === 1 && 'link-disable'}`}
            >
              Previous
            </Link>
          </div>
          <div className="button__one">
            <Link
              to={`/product/${product.id + 1}`}
              className={`${product.id === products.length && 'link-disable'}`}
            >
              Next
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DetailSection;
