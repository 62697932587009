import { Link } from "react-router-dom";

const ProductItem = ({ product }) => {
  return (
    <div className="col-lg-3 col-md-6 colsm-12">
      <div className="portfolio__block p_relative">
        <div className="portfolio__image">
          <figure>
            <img src={product.imageForHome} alt="product" />
          </figure>
        </div>
        <div className="lower__content p_absolute">
          <div className="protfolio__text">
            <div className="text__block">
              <h4>{product.client}</h4>
              <p>{product.category}</p>
            </div>
            <div className="text__block_two">
              <h5>{product.year}</h5>
            </div>
          </div>
          <div className="protfolio__button">
            <Link
              to={`/product/${product.id}`}
              className="theme-btn theme-btn-one"
            >
              {" "}
              Read More<i className="icon-02"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
