import { Slide } from "react-awesome-reveal";

const BlogSection = () => {
  return (
    <section className="blog__section see__pad p_relative">
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="title__data">
              <div className="sub__title">
                <h4>Latest News </h4>
              </div>
              <div className="title">
                <h2>Learn From Blog</h2>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="normaol__text">
              <p>
                Our dedicated team of technology professionals comprises
                experienced engineers, developers, and researchers who are
                passionate about pushing the boundaries of technology.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 news__block">
            <Slide direction="up" duration={500} triggerOnce>
              <div className="news___block wow slideInUp animated animated animated">
                <div className="inner-box p_relative">
                  <div className="image-box p_relative d_block">
                    <figure className="image p_relative d_block">
                      <a
                        href="https://medium.com/apexic-solutions/navigating-the-software-consultancy-horizon-a-symbiotic-approach-to-development-and-talent-8961bb8745b5?source=collection_home---5------2-----------------------"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          src="assets/news-1.png"
                          alt=""
                          className="home-blog-image"
                        />
                      </a>
                    </figure>
                    <div className="post__date">
                      <ul>
                        <li>
                          {" "}
                          <i className="icon-15"></i>7 October 2022
                        </li>
                        <li className="two"></li>
                        <li>
                          <i className="icon-09"></i> Admin
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="lower___content p_relative">
                    <h4>
                      <a
                        href="https://medium.com/apexic-solutions/navigating-the-software-consultancy-horizon-a-symbiotic-approach-to-development-and-talent-8961bb8745b5?source=collection_home---5------2-----------------------"
                        rel="noreferrer"
                        target="_blank"
                        className="home-blog-text"
                      >
                        Navigating the Software Consultancy Horizon: A Symbiotic
                        Approach to Development and Talent Management For
                        Entrepreneurs, and Enterprises
                      </a>
                    </h4>

                    <div className="btn__box__two">
                      <a
                        href="https://medium.com/apexic-solutions/navigating-the-software-consultancy-horizon-a-symbiotic-approach-to-development-and-talent-8961bb8745b5?source=collection_home---5------2-----------------------"
                        rel="noreferrer"
                        target="_blank"
                        className="theme__btn__two"
                      >
                        <i className="icon-02"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 news__block">
            <Slide direction="up" duration={800} triggerOnce>
              <div className="news___block wow slideInUp animated animated animated">
                <div className="inner-box p_relative">
                  <div className="image-box p_relative d_block">
                    <figure className="image p_relative d_block">
                      <a
                        href="https://medium.com/apexic-solutions/merging-the-powers-of-blockchain-and-artificial-intelligence-9175a73a1d0d?source=collection_home---5------1-----------------------"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          src="assets/news-2.png"
                          alt=""
                          className="home-blog-image"
                        />
                      </a>
                    </figure>
                    <div className="post__date">
                      <ul>
                        <li>
                          {" "}
                          <i className="icon-15"></i>23 March 2023
                        </li>
                        <li className="two"></li>
                        <li>
                          <i className="icon-09"></i> Admin
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="lower___content p_relative">
                    <h4>
                      <a
                        href="https://medium.com/apexic-solutions/merging-the-powers-of-blockchain-and-artificial-intelligence-9175a73a1d0d?source=collection_home---5------1-----------------------"
                        rel="noreferrer"
                        target="_blank"
                        className="home-blog-text"
                      >
                        Merging the Powers of Blockchain and Artificial
                        Intelligence
                      </a>
                    </h4>

                    <div className="btn__box__two">
                      <a
                        href="https://medium.com/apexic-solutions/merging-the-powers-of-blockchain-and-artificial-intelligence-9175a73a1d0d?source=collection_home---5------1-----------------------"
                        rel="noreferrer"
                        target="_blank"
                        className="theme__btn__two"
                      >
                        <i className="icon-02"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 news__block">
            <Slide direction="up" duration={1100} triggerOnce>
              <div className="news___block wow slideInUp animated animated animated">
                <div className="inner-box p_relative">
                  <div className="image-box p_relative d_block">
                    <figure className="image p_relative d_block">
                      <a
                        href="https://medium.com/apexic-solutions/embarking-on-a-tech-journey-navigating-through-the-waves-with-react-native-and-ionic-react-d4275c606391?source=collection_home---5------0-----------------------"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          src="assets/news-3.png"
                          alt=""
                          className="home-blog-image"
                        />
                      </a>
                    </figure>
                    <div className="post__date">
                      <ul>
                        <li>
                          {" "}
                          <i className="icon-15"></i>16 July 2023
                        </li>
                        <li className="two"></li>
                        <li>
                          <i className="icon-09"></i> Admin
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="lower___content p_relative">
                    <h4>
                      <a
                        href="https://medium.com/apexic-solutions/embarking-on-a-tech-journey-navigating-through-the-waves-with-react-native-and-ionic-react-d4275c606391?source=collection_home---5------0-----------------------"
                        rel="noreferrer"
                        target="_blank"
                        className="home-blog-text"
                      >
                        Embarking on a Tech Journey: Navigating through the
                        Waves with React Native and Ionic React
                      </a>
                    </h4>

                    <div className="btn__box__two">
                      <a
                        href="https://medium.com/apexic-solutions/embarking-on-a-tech-journey-navigating-through-the-waves-with-react-native-and-ionic-react-d4275c606391?source=collection_home---5------0-----------------------"
                        rel="noreferrer"
                        target="_blank"
                        className="theme__btn__two"
                      >
                        <i className="icon-02"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
