const PrideSection = () => {
  return (
    <div className="pride__section p_relative see__pad">
      <div
        className="pride__layer"
        style={{
          backgroundImage: "url(assets/bg-04.jpg)",
        }}
      ></div>
      <div className="auto-container">
        <div className="pride__block">
          <figure>
            <img src="assets/images/resource/icon-04.png" alt="" />
          </figure>
          <h1>We pride ourselves on our excellent support and service</h1>
        </div>
      </div>
    </div>
  );
};

export default PrideSection;
