import { Link } from "react-router-dom";
import { Slide, Rotate } from "react-awesome-reveal";

const AccomplisedSection = () => {
  return (
    <div className="accomplised__section">
      <div className="auto-container">
        <div className="accomplised__data p_relative">
          <div className="pattern-layer">
            <div
              className="pattern-4 p_absolute"
              style={{
                backgroundImage: "url(assets/shape-04.png)",
              }}
            ></div>
          </div>
          <div className="row">
            <div className="col-lg-7 col-md-12">
              <div className="title__data">
                <Slide direction="up" triggerOnce>
                  <div className="sub__title">
                    <h4>What we've accomplised</h4>
                  </div>
                </Slide>
                <Slide direction="up" triggerOnce>
                  <div className="title">
                    <h2>
                      We pride ourselves on <br /> our excellent support <br />{" "}
                      and service
                    </h2>
                  </div>
                </Slide>
              </div>
              <Slide direction="up" triggerOnce>
                <div className="btn-box">
                  <Link
                    to="/contact#contact-form-section"
                    className="theme-btn theme-btn-one"
                  >
                    <i className="icon-02"></i> Contact Us
                  </Link>
                </div>
              </Slide>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4 col-md-12">
              <Rotate>
                <div className="funfact__data">
                  <div className="funfact__content one">
                    <div className="count-outer count-box counted">
                      <h1
                        className="count-text"
                        data-speed="1500"
                        data-stop="4.9"
                      >
                        5
                      </h1>
                    </div>
                    <p>Launched Projects</p>
                  </div>
                  <div className="funfact__content two">
                    <div className="count-outer count-box counted">
                      <span>+</span>
                      <h1
                        className="count-text"
                        data-speed="1500"
                        data-stop="4.9"
                      >
                        30
                      </h1>
                    </div>
                    <p>Global Talents</p>
                  </div>
                  <div className="funfact__content three">
                    <div className="count-outer count-box counted">
                      <span>+</span>
                      <h1
                        className="count-text"
                        data-speed="1500"
                        data-stop="30"
                      >
                        10
                      </h1>
                    </div>
                    <p>Clients Worldwide</p>
                  </div>
                </div>
              </Rotate>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccomplisedSection;
